import React, { Component } from "react"
import {StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import AOS from 'aos'
import Flickity from 'react-flickity-component'
import { Helmet } from "react-helmet"
import Layout from "../layouts"

function myFunction() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 100;
  document.getElementById("progress-bar").style.width = scrolled + "%";
}

function renderBlogCategory(cat, cats){
  let obj = cats.edges.find(o => o.node.wordpress_id === cat);
  return(
    <div>{obj.node.name}</div>
  );
}

function addZero(d){
  return "0" + d;
}

function number_lead_zero(d){ 
  return d < 10 ? addZero(d) : d
}

function renderDate(x){
  var dateTimeParts = x.split('T')
  var date = new Date(x);
  var day = date.getDay();
  var month = date.getMonth();
  var year = date.getFullYear();
  var shortDate = number_lead_zero(month) + '.' + number_lead_zero(day) + '.' + year;
  return(
    <div>{shortDate}</div>
  );
}



class BlogTemplate extends Component {
  
  componentDidMount() {
    AOS.init();
    window.onscroll = function() {
      myFunction();
    };
  }

  // getAnimationState600(){
  //  if (typeof window !== 'undefined' && window.innerWidth > 768){
  //   return "1600"
  //   }else{
  //     return "600"
  //   }
  // }

  // getAnchorStaggeredRowOne(){
  //   if (typeof window !== 'undefined' && window.innerWidth > 768){
  //     return ".staggered_row_one"
  //   }else{
  //     return ""
  //   }
  // }

  render() {
    const post = this.props.data.wordpressWpBlog
    const cats = this.props.data.allWordpressWpBlogCategory
    let house = '';

    let acfColor = '#F3A738';

    if (typeof window === 'undefined'){
      return (
        <Layout>
        <Helmet script={[{
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://excelinstore.com/",
              "name": "Excel | Moving Retail Forward",
              "author": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  },
                  "contactPoint": {
                      "@type": "ContactPoint",
                      "contactType": "customer support",
                      "telephone": "+1-218-998-3800",
                      "email": "contact@excelinstore.com"
                  }
              },
              "description": "We guide clients through the creation of in-store visual merchandising displays and fixtures.",
              "publisher": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  }
              }
            })
          }]}>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>Excel | {post.title}</title>
          <meta name="description" content={post.acf.meta_description} />
          <meta property="og:title" content={"Excel | " + post.title}  />
          <meta property="og:type" content="WebSite" />
          <meta property="og:url" content="https://excelinstore.com/" />
          <meta property="og:image" content={post.acf.post_hero_image.url} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:description" content={post.acf.meta_description} />
          <meta property="og:site_name" content="Excel" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={"Excel | " + post.title} />
          <meta name="twitter:description" content={post.acf.meta_description} />
          <meta name="twitter:image" content={post.acf.post_hero_image.url} />
          
        </Helmet>
        </Layout>
        )
    }else{
      var called = false
      window.addEventListener('mousedown', function() {
        if (called === false){
          let elem = document.getElementById('excel-global-header-inner')
          elem.classList.add('using-mouse');
          called = true
        }
      });

      return(
        <Layout>
        <Helmet script={[{
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://excelinstore.com/",
              "name": "Excel | Moving Retail Forward",
              "author": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  },
                  "contactPoint": {
                      "@type": "ContactPoint",
                      "contactType": "customer support",
                      "telephone": "+1-218-998-3800",
                      "email": "contact@excelinstore.com"
                  }
              },
              "description": "We guide clients through the creation of in-store visual merchandising displays and fixtures.",
              "publisher": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  }
              }
            })
          }]}>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>Excel | {post.title}</title>
          <meta name="description" content={post.acf.meta_description} />
          <meta property="og:title" content={"Excel | " + post.title}  />
          <meta property="og:type" content="WebSite" />
          <meta property="og:url" content="https://excelinstore.com/" />
          <meta property="og:image" content={post.acf.post_hero_image.url} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:description" content={post.acf.meta_description} />
          <meta property="og:site_name" content="Excel" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={"Excel | " + post.title} />
          <meta name="twitter:description" content={post.acf.meta_description} />
          <meta name="twitter:image" content={post.acf.post_hero_image.url} />
          
        </Helmet>
        <div className="progress"><div id="progress-bar" style={{backgroundColor: acfColor}}></div></div>
        {post.acf &&
          post.acf.post_hero_image &&
          <div className="hero--wrapper" data-aos="fade-up" data-aos-delay="300">
            <div className="hero--full-bleed">
              <div className="image" style={{'backgroundImage': "url(" + post.acf.post_hero_image.url + ")"}}>
              </div>
            </div>
          </div>
        }
        <h1 className="blog-post-title" dangerouslySetInnerHTML={{ __html: post.title }} data-aos="fade-left" data-aos-delay="600" data-aos-anchor=".hero--wrapper"/>
        <div className="post-wrapper cf" data-aos="fade-up" data-aos-delay="900">
        { post.acf && 
          post.acf.post_info &&
          <div className="post-info">
            <div className="post-information--top">
              { post.acf.post_info.author_image &&
                <div className="author_image" style={{'backgroundImage': "url(" + post.acf.post_info.author_image.url + ")"}} />
              }
              { post.acf.post_info.author_name &&
                <div className="author_name">{post.acf.post_info.author_name}</div>
              }
              {
                <div className="blog_date">
                  {
                    renderDate(post.date)
                  }
                </div>
              }
              {
                <div className="blog_category">
                  {
                    renderBlogCategory(post.blog_category[0], cats)
                  }
                </div>
              }
            </div>

            <div className="post-information--bottom">

            { post.acf.post_info.listing_information !== null &&
              <div>
                { 
                  post.acf.post_info.listing_information.map((ul, i) => {
                    return (
                      <ul className="list-wrapper">
                          <span className="list-title">{ul.list_title}</span>
                          {
                            ul.list_items.map((li, i) => {
                              return (
                                <li className="list-item" key={"li" + i}>
                                  {li.list_item}
                                </li>
                              )
                            })
                          }
                      </ul>
                    )
                  })
                }
              </div>
            }
            </div>
          </div>
        }
        <div className="post-content">
          {post.acf &&
            post.acf.post_content_blog &&
            post.acf.post_content_blog.map((layout, i) => {
              if (layout.__typename === `WordPressAcf_full_width_copy`) {
                return (
                  <div key={`${i} full_width_copy`} data-aos="fade-up" data-aos-delay="150">
                    <div className="full_width_copy" dangerouslySetInnerHTML={{ __html: layout.full_width_copy }} />
                  </div>
                )
              }
              if (layout.__typename === `WordPressAcf_large_header`) {
                return (
                  <div key={`${i} large_header`} data-aos="fade-up" data-aos-delay="150">
                    <div className="large_header" dangerouslySetInnerHTML={{ __html: layout.large_header }} />
                  </div>
                )
              }
              if (layout.__typename === `WordPressAcf_small_header`) {
                return (
                  <div key={`${i} small_header`} data-aos="fade-up" data-aos-delay="150">
                    <div className="small_header" dangerouslySetInnerHTML={{ __html: layout.small_header }} />
                  </div>
                )
              }
              if (layout.__typename === `WordPressAcf_images`) {
                return (
                  <div key={`${i} two_colummn_images`} className="two_colummn_images" data-aos="fade-up" data-aos-delay="150">
                    { layout.images.length > 1 &&
                      <Flickity flickityRef={c => this.flkty = c} className="images">
                       { 
                          layout.images.map((image, i) => {
                            return (
                              <div className="image" key={"image" + i}>
                                <img src={image.image.url} />
                              </div>
                            )
                          })
                        }
                      </Flickity>
                    }
                    { layout.images.length < 2 &&
                      <div className="image">
                        <img src={layout.images[0].image.url} />
                      </div>
                    }
                  </div>
                )
              }
              if (layout.__typename === `WordPressAcf_full_width_quote`) {
                return (
                  <div key={`${i} full_width_quote`} className="full_width_quote_container" data-aos="fade-up" data-aos-delay="150">
                    <div className="full_width_quote">“{layout.full_width_quote}”</div>
                    <div className="full_width_quote__author">— {layout.author}</div>
                  </div>
                )
              }
              return null
            })
          }
        </div>
        </div>
      </Layout>
    )
  }
  }
}

BlogTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default BlogTemplate

export const pageQuery = graphql`
  query($id: String!) {
    allWordpressWpBlogCategory {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    wordpressWpBlog(id: { eq: $id }) {
      id
      slug
      title
      content
      date
      blog_category
      acf {
        meta_description
        post_info {
          author_name
          author_image {
            url
            caption
            alt
            title
          }
          listing_information {
            list_items {
              list_item
            }
            list_title
          }
        }
        post_content_blog {
          ... on WordPressAcf_full_width_copy {
            id
            full_width_copy
          }
          ... on WordPressAcf_images {
            images {
              image {
                alt
                caption
                url
                title
              }
            }
          }
          ... on WordPressAcf_large_header {
            id
            large_header
          }
          ... on WordPressAcf_small_header {
            id
            small_header
          } 
          ... on WordPressAcf_full_width_quote {
            id
            full_width_quote
            author
          }
        }
        post_hero_image {
          alt
          caption
          url
          title
        }
      }
    }
    site {
      siteMetadata {
        title
        subtitle
      }
    }
  }
`
